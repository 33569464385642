import React, { useEffect, useState } from 'react';
import { useSignalAbort } from '../api/useSignalAbort';
import { createDatasorce, updateDatasorce } from '../api/connectorApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { toast } from 'react-toastify';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
// import { IoEye } from "react-icons/io5";
// import { IoMdEyeOff } from "react-icons/io";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNotification } from '../pages/firebase/NotificationContext';
import { handleForegroundMessages } from '../pages/firebase/FirebaseForegroundHandler';

function DynamicForm({ configSchema, selectedConnector, customerId, isEdit, closeModal, connectionName, notification, setNotification, setCreatedConnection }) {
    const abortConstants = useSignalAbort()
    // const { notification } = useNotification()
    const [formData, setFormData] = useState(isEdit ? selectedConnector?.ConnectionData : {});
    const [isLoading, setIsLoading] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState({})
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const parsedSchema = JSON.parse(configSchema); // Parse ConfigSchema
    const currentStep = parsedSchema.steps[currentStepIndex];
    // console.log(parsedSchema);

    // const [notification, setNotification] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [createdConnector, setCreatedConnector] = useState();

    useEffect(() => {
        // Listen for foreground messages
        handleForegroundMessages((message) => {
            // console.log(message);
            setNotification(message); // Update the notification state
        });
    }, []);

    useEffect(() => {
        if (notification) {
            // && notification.data.ConnectionId === selectedConnector?.ConnectionId
            setIsSaving(false)
            if (notification.data.status === 'Success') {
                closeModal(true)
                return
            } else if (notification.data.status === 'Incorrect') {
                setIsUpdate(true)
            } else {
                const conditionalStep = currentStep?.conditional_step
                // console.log(conditionalStep);
                
                if (conditionalStep) {
                    const { field, value, next_step } = conditionalStep;
                    console.log(field, value, next_step);
                    
                    if (field === 'fcm') {
                        if (notification.data.status === value) {
                            setIsUpdate(true)
                            setCurrentStepIndex(next_step - 1); // Navigate to the next step (adjust for 0-based index)
                            return;
                        }
                   }
                }
            }
        } 
        // else {
        //     setIsSaving(false)
        // }
    }, [notification])

    //for adding data source
    const addConnector = async (data) => {
        // console.log(data);
        try {
            const result = await createDatasorce(data, abortConstants.controllerConfig)
            // console.log(result);
            if (result) {
                setCreatedConnector(result?.data?.DataSourceConnection)
                setIsUpdate(true)
                setCreatedConnection(result?.data?.DataSourceConnection?.ConnectionId)
                // successToast(result.data.message)
                // closeModal(true)
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Create Datasource', 'addconnector_response', 'addconnector_timeout', 'addconnector_request', setIsLoading)
        }
    }

    //for updating data source
    const editConnector = async (data) => {
        // console.log(data);
        try {
            const result = await updateDatasorce(selectedConnector?.ConnectionId, data, abortConstants.controllerConfig)
            // console.log(result);
            if (result) {
                // successToast(result.data.message)
                // closeModal(true)
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Datasource', 'editconnector_response', 'editconnector_timeout', 'editconnector_request', setIsLoading)
        }
    }

    // Handle input changes
    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        // Clear the error for this field on input change
        setError((prevError) => ({
            ...prevError,
            [field]: "",
        }));
    };

    const togglePasswordVisibility = (fieldName) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [fieldName]: !prevState[fieldName],
        }));
    };

    const formSubmit = () => {
        const newData = {
            ConnectionName: selectedConnector[connectionName],
            DataSourceId: selectedConnector?.DataSourceId,
            CustomerId: customerId,
            ConnectionData: formData,
            ConnectionStatus:0
        }

        if(isUpdate){
            editConnector({...createdConnector, ConnectionData: formData})
            return;
        }

        if (isEdit) {
            editConnector({
                ...selectedConnector, ConnectionData: formData
            })
        } else {
            addConnector(newData)
        }
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setNotification(null)
        setIsSaving(true)
        let isValid = true;
        const newErrors = {};
        for (const field of currentStep.fields) {

            const value = formData[field.APIName] || '';

            // Check "Required" rule
            if (field.Validation.some((v) => v.RuleType === "Required") && !value) {
                newErrors[field.APIName] = field.Validation.find((v) => v.RuleType === "Required")?.ErrorMessage || `${field.DisplayLabel} is required.`;
                isValid = false;
            }

            // Check "Length" rule
            const lengthRule = field.Validation.find((v) => v.RuleType === "Length");

            if (lengthRule && value.length < parseInt(lengthRule.RuleValue, 10)) {
                // toast.error(lengthRule.ErrorMessage || `${field.DisplayLabel} exceeds maximum length of ${lengthRule.RuleValue}.`);
                isValid = false;
                newErrors[field.APIName] =
                    lengthRule.ErrorMessage || `${field.DisplayLabel} must be at least ${lengthRule.RuleValue} characters long.`;
            }
        }

        setError(newErrors);
        // If validation fails, do not proceed
        if (!isValid) {
            setIsSaving(false)
            return;
        }

        // const conditionalStep = currentStep?.conditional_step;
        // if (conditionalStep) {
        //     // Check if the condition matches
        //     const { field, value, next_step } = conditionalStep;
        //     if (formData[field] === value) {
        //         setCurrentStepIndex(next_step - 1); // Navigate to the next step (adjust for 0-based index)
        //         return;
        //     }
        // }

        // If no condition or condition fails, finalize the submission
        formSubmit();
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2">
            <span className="mb-3 dynamic-form-headline connector-form-label bold" dangerouslySetInnerHTML={{ __html: currentStep.step_name }} />
            <span className='dynamic-form-subtitle mb-2' dangerouslySetInnerHTML={{ __html: currentStep.step_description.slice(0, 65) }} />
            <span className='mb-4 dynamic-form-subtitle' dangerouslySetInnerHTML={{ __html: currentStep.step_description.slice(69, 100) }} />
            {!isSaving ?
                <form onSubmit={handleSubmit} className="dynamic-form-div">
                    {currentStep.fields.map((field, index) => {
                        const lengthRule = field.Validation.find((v) => v.RuleType === "Length");

                        return (
                            <div key={index} className="form-group mb-3 position-relative">
                                {field.FieldType === "checkbox" ? (
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id={field.APIName}
                                            name={field.APIName}
                                            className="form-check-input"
                                            checked={!!formData[field.APIName]}
                                            onChange={(e) => handleInputChange(field.APIName, e.target.checked)}
                                        />
                                        <label htmlFor={field.APIName} className="form-check-label">
                                            {field.DisplayLabel}
                                        </label>
                                    </div>
                                ) : (
                                    <>
                                        <label style={{ marginBottom: '8px', display: 'block', color: '#489C8F', fontWeight: 500 }} id={field.FieldLabel}>{field.DisplayLabel}</label>
                                        <input
                                            type={field.FieldType === "password" && showPassword[field.APIName] ? "text" : field.FieldType}
                                            id={field.APIName}
                                            name={field.APIName}
                                            placeholder={field.DisplayLabel}
                                            className="form-control"
                                            style={{ marginTop: '8px' }}
                                            value={formData[field.APIName] || ""}
                                            maxLength={lengthRule ? parseInt(lengthRule.RuleValue, 10) : undefined}
                                            onChange={(e) => handleInputChange(field.APIName, e.target.value)}
                                        />
                                        {field.FieldType === "password" && (
                                            <span
                                                className="position-absolute"
                                                style={{
                                                    top: '70%',
                                                    right: '10px', // Adjust spacing from the right edge of the input
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    zIndex: 10
                                                }}
                                                onClick={() => togglePasswordVisibility(field.APIName)}
                                            >
                                                {showPassword[field.APIName] ? <VisibilityOffIcon fontSize='10px' /> : <VisibilityIcon fontSize='10px' />}
                                            </span>
                                        )}
                                        {error[field.APIName] && <div className='form-error text'>{error[field.APIName]}</div>}
                                    </>
                                )}
                            </div>
                        )
                    })}
                    {notification && notification.data.status === 'Incorrect' && (
                        <span className="form-error text mb-3">
                            {currentStep?.ErrorMessage
                            }
                        </span>
                    )}
                    <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className="me-2">Saving...</span>
                                <Spinner animation="border" variant="light" size="sm" />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    {/* {currentStep?.conditional_step?.value ?
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <Button type="submit" variant='outline-secondary' className='w-100 button skip-button'>
                            Skip for now
                        </Button>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                            {isSaving ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="me-2">Saving...</span>
                                    <Spinner animation="border" variant="light" size="sm" />
                                </div>
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </Col>
                </Row>
            </div> :
            <Button type="submit" className={`w-100 ${isSaving ? 'button-loading disable' : 'button save'}`}>
                {isSaving ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="me-2">Saving...</span>
                        <Spinner animation="border" variant="light" size="sm" />
                    </div>
                ) : (
                    "Submit"
                )}
            </Button>
        } */}

                </form>
                :
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <span>Validating User Credentials...</span>
                    <img className="flip-animation mt-3 mb-3" src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} alt='logo' width={80} height={60} />
                </div>
            }
        </div>
    );
}

export default DynamicForm;
