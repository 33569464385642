import axiosInstance from "./datasourceAxiosInstance";

const api = axiosInstance()

//function for getting all workspaces
export const getCategory = (abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/DataSourceType`, config)
}

//function for getting all workspaces
export const getConnectors = (id, abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/DataSource/ByCriteria?TypeId=${id}`, config)
}

//function for getting all workspaces
export const getDatasorces = (id, abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/DataSourceConnection/ByCriteria?CustomerId=${id}`, config)
}

//function for getting all workspaces
export const getDatasorcesById = (id, abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/DataSourceConnection/${id}`, config)
}

//function for creating a data source
export const createDatasorce = (data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.post(`/DataSourceConnection`, data, config)
}

//function for updating a lead note
export const updateDatasorce = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/DataSourceConnection/${id}`, data ,config)
}

//function for deleting a lead note
export const deleteDatasource = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.delete(`/DataSourceConnection/${id}`,config)
}

//function for getting all workspaces
export const getCustomerLicenseKey = (id, abortToken) => {
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
  };
  return api.get(`/UserSubscriptions?CustomerId=${id}`, config)
}