import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material'

function SyncTally({ getOnboardingStep }) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-2 ">
            <span className='mt-4 tally tally-headline'>Please connect your Tally data with RCOR Connector</span>
            <span className='mt-5'> To make sure you get the most out of our platform,  </span>
            <span className='mt-2'>please connect your Tally data to the RCOR connector.  </span>
            <span className='mt-2'>This simple step keeps your data current and unlocks all our features for you.</span>
            <span>
                If your Tally data already connected, please refresh
                <IconButton onClick={() => getOnboardingStep()}><RefreshIcon className='common-icon' /></IconButton>
            </span>
        </div>
    )
}

export default SyncTally