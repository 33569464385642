import React, { useEffect, useState } from 'react'
import InitialPage from './InitialPage'
import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { useSignalAbort } from '../api/useSignalAbort';
import { onBoarding } from '../api/userApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { useNavigate } from 'react-router-dom';
import NotSync from '../CustomerOnboarding/NotSync';
import CompanyProfile from '../CustomerOnboarding/CompanyProfile';
import BranchProfile from '../CustomerOnboarding/BranchProfile';
import UserProfile from '../CustomerOnboarding/UserProfile';
import { createDatasorce, updateDatasorce } from '../api/connectorApi';
import TallyBranch from './TallyBranch';
import SyncTally from './SyncTally';
import TallyCompany from './TallyCompany';
import TallyUser from './TallyUser';
import SuccessPopup from '../datasource/SuccessPopup';

function TallyDatasource({ tallyClose, selectedConnector, isEdit }) {
  console.log(selectedConnector);

  const user = useSelector(state => state.user);
  const [step, setStep] = useState(0)
  const [isedit, setIsEdit] = useState(false)


  const navigate = useNavigate()
  const localToken = localStorage.getItem("token")
  const abortConstants = useSignalAbort()
  const [isLoading, setIsLoading] = useState({})
  const [currentStep, setCurrentStep] = useState(null)


  // useEffect(() => {
  //     if (localToken && user) {
  //         getOnboarding()
  //         // getLedgerGroup()
  //     } else {
  //         navigate('/loginpage')
  //     }

  //     return () => {
  //         // Cancel the request before the component unmounts
  //         abortConstants.controller.abort()
  //     }
  // }, [user, localToken])

  // useEffect(()=>{
  //   getOnboarding(1)
  // }, [])

  //for adding data source
  const addConnector = async () => {
    const data = {
      ConnectionName: selectedConnector?.DataSourceName,
      DataSourceId: selectedConnector?.DataSourceId,
      CustomerId: user?.CustomerId,
      ConnectionData: '',
      ConnectionStatus: 2
    }
    console.log(data);

    try {
      const result = await createDatasorce(data, abortConstants.controllerConfig)
      console.log(result);
      if (result) {
        setIsEdit(true)
        getOnboarding(1)
      }
    }
    catch (error) {
      getOnboarding(1)
      // handleApiErrors(error, 'Create Datasource', 'addconnector_response', 'addconnector_timeout', 'addconnector_request', setIsLoading)
    }
  }
  //for updating data source
  const editConnector = async () => {
    // console.log(data);
    const data = { ...selectedConnector, ConnectionStatus: 1 }
    try {
      const result = await updateDatasorce(selectedConnector?.ConnectionId, data, abortConstants.controllerConfig)
      // console.log(result);
      if (result) {
        // successToast(result.data.message)
        // closeModal(true)
        window.location.href = '/'
      return null
      }
    }
    catch (error) {
      handleApiErrors(error, 'Update Datasource', 'editconnector_response', 'editconnector_timeout', 'editconnector_request', setIsLoading)
    }
  }

  // API call to get onboarding details
  const getOnboarding = async (data) => {
    try {
      const result = await onBoarding(data, abortConstants.controllerConfig)
      console.log(result)
      if (result && result?.data?.OnboardingProcess) {
        setCurrentStep(result.data.OnboardingProcess[0]?.Id)
      }
    }
    catch (error) {
      handleApiErrors(error, 'OnBoarding', 'boarding_response', 'boarding_timeout', 'boarding_request', setIsLoading)
    }
  }

  const getData = () => {
    getOnboarding(1)
  }

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const goToNextStep = () => {
    if (currentStep >= 0) {
      setCurrentStep(currentStep + 1)
    }
  }
  console.log(currentStep);

  // Render the component based on the current step
  // const renderStepComponent = () => {
  //   switch (currentStep) {
  //     case 'SyncBranch':
  //       return <TallyBranch />;
  //     case 'SyncCompany':
  //       return <CompanyProfile />;
  //     case 'SyncUser':
  //       return <UserProfile />;
  //     default:
  //       setCurrentStep(null);
  //       return null
  //   }
  // };

  const success = () => {
    editConnector()
  }

  const renderStepComponent = () => {
    if (currentStep === null) {
      return <InitialPage customerId={user?.CustomerId} />;
    }

    switch (currentStep) {
      case 0:
        console.log("Not Sync");
        return <SyncTally getOnboardingStep={getData} />;
      case 1:
        return (
          <TallyCompany
            getOnboardingStep={getData}
            goToNextStep={goToNextStep}
          />
        );
      case 2:
        return (
          <TallyBranch
            getOnboardingStep={getData}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 3:
        return (
          <TallyUser
            getOnboardingStep={getData}
            goToPreviousStep={goToPreviousStep}
          />
        );
      
      default:
        return <SuccessPopup connectorLogo={selectedConnector?.LogoURL} back={()=>success()} closeModalPopup={tallyClose}/>
    }
  };



  return (
    <div className="tally-centered-container">
      <div className="tally-content-container">
        {/* Content area */}
        <div className="tally-content">
          {/* <InitialPage customerId={user?.CustomerId} /> */}
          {renderStepComponent()}
          {/* {currentStep ? renderStepComponent() : <InitialPage customerId={user?.CustomerId} />} */}
        </div>
        {/* Footer buttons */}
        <div className="tally-footer">
          <Row>
            <Col xs={6} className="d-flex justify-content-start">
              <span className='icon-cursor' onClick={() => tallyClose()}>Skip</span>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <Button className='button save' onClick={() => isedit ? getData() : addConnector()}>Next</Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default TallyDatasource