import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Header from '../CustomerOnboarding/Header';
import { Link, useNavigate } from 'react-router-dom';
import { getUserMenu } from '../api/userApi';
import { useSelector } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { requestPermissionAndSendToken } from './firebase/FirebaseGetToken';

function NoIntegrations() {
    const userToken = localStorage.getItem("token")
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const [hasWorkspaces, setHasWorkspaces] = useState(true); // Tracks if dashboards exist

    const getStart = ()=>{
        requestPermissionAndSendToken(user?.CustomerId, userToken)
        navigate('/onBoarding_datasource')
    }

    const requestPermission = async () => {
        console.log("Requesting permission...");
        const result = await requestPermissionAndSendToken(user?.CustomerId, userToken);
        console.log("Permission result:", result);
    
        if (result) {
            navigate('/onBoarding_datasource');
        } else {
            alert("You need to allow notifications to proceed.");
        }
    };

    return (
        <Box>
            <Header />
            <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} display="flex" justifyContent="center">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/welcome.png'}
                                alt="not found"
                                // className="image common"
                                height={250}
                                width={250}
                            />
                            <Typography mt={3} mb={1} className='datasource datasource-heading'>
                                Welcome to RCOR Intelliger!   &nbsp;<img
                                src={process.env.PUBLIC_URL + '/assets/images/confetti icon.png'}
                                alt="not found"
                                // className="image common"
                                height={30}
                                width={30}
                            />
                            </Typography>
                            <Typography mt={4} mb={1} className='datasource datasource-subtitle' >
                                We’re thrilled to have you onboard! <img
                                src={process.env.PUBLIC_URL + '/assets/images/star icon.png'}
                                alt="not found"
                                // className="image common"
                                height={18}
                                width={18}
                            /> You’re now one step closer to unlocking the power of data-driven decisions
                            </Typography>
                            <Typography mb={1} className='datasource datasource-subtitle'>
                                for your business.
                            </Typography>
                            <Typography mb={1} className='datasource datasource-subtitle'>
                                Before we dive in, let’s make sure your data sources are connected to unleash the full potential of your RCOR
                            </Typography>
                            <Typography mb={5} className='datasource datasource-subtitle'>
                                Intelliger platform.
                            </Typography>
                            <Button onClick={() => requestPermission()} className='datasource datasource-button'>Get Started &nbsp;<EastIcon /></Button>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default NoIntegrations;
