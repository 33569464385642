import React from 'react'
import Branch from '../pages/masterFiles/Branch'
import CompanySettings from '../pages/Settings/CompanySettings/CompanySettings'

function TallyCompany({getOnboardingStep}) {
    const goToNextStep =()=>{
        // getOnboardingStep()
    }
  return (
    <div>
        <CompanySettings onBoarding={true} getOnboardingStep ={getOnboardingStep} goToNextStep={goToNextStep}/>
    </div>
  )
}

export default TallyCompany