import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { getCategory, getConnectors } from '../api/connectorApi';
import { handleApiErrors } from '../api/HandleApiErrors';
import { useSignalAbort } from '../api/useSignalAbort';
import DynamicForm from './DynamicForm';
import ConnectorModal from './ConnectorModal';
import axios from 'axios';
import TallyDatasource from '../tallyDatasource/TallyDatasource';

function Connectors({ customerId, closePopup, closeModalPopup, refreshData, datasorces }) {
    const abortConstants = useSignalAbort()
    const [categories, setCategories] = useState()
    const [connectors, setConnectors] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedConnector, setSelectedConnector] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [modalShow, setModalShow] = useState(false);
    const [svgContent, setSvgContent] = useState({});

    // Fetch the SVG content
    const fetchSVG = async (url, id) => {
        try {
            const response = await axios.get(url, { responseType: "text" });
            setSvgContent((prev) => ({ ...prev, [id]: response.data }));
        } catch (error) {
            console.error("Error fetching SVG:", error);
        }
    };

    useEffect(() => {
        // Fetch SVGs for each connector
        connectors?.forEach((connector) => {
            const url = `https://rcorintelliger.co:8021${connector.LogoURL}`;
            fetchSVG(url, connector.LogoURL);
        });
    }, [connectors]);

    useEffect(() => {
        getAllCategory()
    }, [])

    //connectors
    const getAllCategory = async () => {
        try {
            const result = await getCategory(abortConstants.controllerConfig);
            // console.log(result);
            setCategories(result?.data?.DataSourceType)
            setSelectedCategory(result?.data?.DataSourceType[0]?.DataSourceTypeId)
            getConnector(result?.data?.DataSourceType[0]?.DataSourceTypeId)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    //connectors
    const getConnector = async (id) => {
        try {
            const result = await getConnectors(id, abortConstants.controllerConfig);
            console.log(result);
            setConnectors(result?.data?.DataSource)
        }
        catch (error) {
            console.log(error);
            handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
        }
    };

    const setCategory = (id) => {
        setSelectedCategory(id)
        getConnector(id)
    }

    const getConnectorForm = (connector) => {
        setSelectedConnector(connector)
        setModalShow(true)
    }

    const handleCloseModal = (isEdit) => {
        setModalShow(false)
        closePopup(isEdit)
        // getConnector(selectedConnector?.DataSourceId)
    }

    const handleCloseModalPopup = () => {
        setModalShow(false)
        closeModalPopup()
        // getAllCategory()
    }

    const tallyClose = () => {
        setModalShow(false)
        setSelectedConnector(null)
    }

    return (
        <>{!modalShow ?
            <div>
                {selectedConnector?.DataSourceName !== 'Tally' &&<Row>
                    <Col xs={12} md={8}>
                        <div className='data-source heading'>Select Connectors</div>
                        <div className='data-source subtitle'>
                            Choose your preferred data integration app
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='d-flex justify-content-end'>
                    {datasorces && datasorces?.length > 0 &&<Button className='button datasource-add' onClick={() => refreshData()}>
                            Back
                        </Button>}
                    </Col>
                </Row>}
                <Row className='mt-md-5'>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={8} className='connector-div'>
                        <Row className='mt-3'>
                            <Col xs={12} md={3} className='col-rightBorder'>
                                <span className='connector-form-label data-source heading'>Category</span>
                                <div className="mt-2">
                                    {categories && categories?.length > 0 ? (
                                        categories.map((category, index) => (
                                            <div key={index} className={`connector-form-label ${category.DataSourceTypeId === selectedCategory ? 'connector-form-label-color' : ''} common-icon mb-1`} onClick={() => setCategory(category.DataSourceTypeId)}>
                                                {category.DataSourceTypeName}
                                            </div>
                                        ))
                                    ) : (
                                        <div>No categories available</div>
                                    )}
                                </div>
                            </Col>
                            {selectedCategory && <Col xs={12} md={9}>
                                <Row>
                                    {connectors && connectors?.length > 0 ? (
                                        connectors.map((connector, index) => (
                                            <Col key={index} xs={6} md={3} className='mt-2'>
                                                <div className='connector-card connector-card-border common-icon' onClick={() => getConnectorForm(connector)}>
                                                    <img
                                                        src={`https://rcorintelliger.co:8021${connector.LogoURL}`}
                                                        className="connector-img" // Added img-fluid for responsiveness
                                                        alt=""
                                                    />
                                                    {/* {svgContent[connector.LogoURL] ? (
                                                // Inject the inline SVG
                                                <div
                                                    className="connector-img"
                                                    dangerouslySetInnerHTML={{ __html: svgContent[connector.LogoURL] }}
                                                />
                                            ) : (
                                                <span>Loading...</span>
                                            )} */}
                                                    <span className='connector-form-label'>{connector.DataSourceName}</span>
                                                    {/* <Form.Check // prettier-ignore
                                            type='checkbox'
                                            id='default-checkbox'
                                        /> */}
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        <div>No connectors available</div>
                                    )}
                                </Row>
                            </Col>}
                        </Row>
                    </Col>
                    <Col xs={12} md={2}></Col>
                </Row>
            </div>
            :
            <div className='mt-5'>
                {selectedConnector?.DataSourceName === 'Tally' ?
                    <TallyDatasource tallyClose={tallyClose} selectedConnector={selectedConnector}/>
                    :
                    <ConnectorModal selectedConnector={selectedConnector} id={customerId} closePopup={handleCloseModal} connectionName='DataSourceName' isEdit={false} closeModalPopup={handleCloseModalPopup} />
                }
            </div>
        }</>
    )
}

export default Connectors