import React, { createContext, useContext, useState, useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { app } from "./FirebaseConfig"; // Firebase config

const messaging = getMessaging(app);

// Create the Notification Context
const NotificationContext = createContext();

// Notification Provider Component
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    // Listen to foreground messages
    onMessage(messaging, (payload) => {
      console.log("Foreground message received: ", payload);

      // Set notification data
      const { title, body, data } = payload.notification || payload.data || {};
      setNotification({ title, body, data });

      // Example: Auto-clear notification after 5 seconds
      setTimeout(() => setNotification(null), 5000);
    });
  }, []);

  return (
    <NotificationContext.Provider value={{ notification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook to use the notification context
export const useNotification = () => useContext(NotificationContext);
