import { onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { app } from "./FirebaseConfig"; // Your Firebase configuration file

const messaging = getMessaging(app);

export const handleForegroundMessages = (setMessageCallback) => {
  onMessage(messaging, (payload) => {
    console.log("Foreground message received: ", payload);

    // Access notification data
    // const { title, body, data } = payload.notification || payload.data;
    const data = payload.data || {};

    const title = payload.notification.title || "";
    const body = payload.notification.body || "";

    setMessageCallback({ title, body, data });
    // Example: Show an alert or perform some operation
    // if (title && body) {
    //   alert(`Notification: ${title} - ${body}`);
    // }

    // If data is included in the notification
    // if (data) {
    //   console.log("Notification Data: ", data);

    //   // Example: Update application state or redirect to another page
    //   if (data.action === "updatePage") {
    //     navigateToPageWithData(data); // Replace with your actual navigation logic
    //   }
    // }
  });
};

// const navigateToPageWithData = (data) => {
//   console.log("Navigating to page with data: ", data);
//   // Implement your navigation or state update logic here
// };
