import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { logout } from "./userApi";
axios.defaults.mode='cors'
// axios.defaults.withCredentials = true;

const API_URL = "https://rcorintelliger.co:8021";
function navigateToPage() {
  const newPageURL = '/logout'; // Replace with the URL of the page you want to navigate to
  window.location.href = newPageURL;
}

function wrongURLpage() {
  const newPageURL = '/invalid_url'; // Replace with the URL of the page you want to navigate to
  window.location.href = newPageURL;
}

const axiosInstance = () => {
  const token = localStorage.getItem("token")
  const fullURL = window.location.href
  const parsedURL = new URL(fullURL)
  const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')
  // const hostName = 'shafeeq.rcorintelliger.co'
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authority': hostName,
      'withCredentials': true,
    },
    // withCredentials: true,
  });

  // Add an interceptor to set the 'Authorization' header for all requests
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });

   // Add an interceptor to capture and handle response errors
   instance.interceptors.response.use(
    (response) => {
      // console.log("Response:", response);
      if(response.data.code === '101'){
        // wrongURLpage()
        window.location.href = `https://rcorintelliger.co/InvalidURl?url=${hostName}`;
      }
      return response;
    },
    (error) => {
      if (error.response) {
        if (token && error.response.status === 403) {
          console.log(error);
          navigateToPage()
        } 
        if(token && error.response.status === 401){
          toast.error(error?.response?.data?.message, {
              autoClose: 5000
          })
          localStorage.removeItem("token")
          window.location.href = '/loginpage'
          return
      }

      } else if (error.request) {
        console.error('Server error:', error);

      } else {
        // console.error('Error:', error.message);
      }
      return Promise.reject(error);
    });

  return instance;
};

export default axiosInstance;
