import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getUserType =(token)=>{
    return api.get(`/UserType`)
}

export const getUserRole =(id, token)=>{
    return api.get(`/UserType/${id}`)
}

export const createUserType =(roleData,token)=>{
    return api.post(`/UserType`, roleData)
}

export const updateUserType =(id, roleData, token)=>{
    return api.put(`/UserType/${id}`, roleData)
}
export const deleteUsertype =(id, token)=>{
    return api.delete(`/UserType/${id}`)
}
export const getCompany =(token)=>{
    return api.get(`/UserType`)
}

export const getDesignation =(token)=>{
    return api.get(`/designation`)
}

export const getUserById =(id, token)=>{
    return api.get(`/user/${id}`)
}

export const getUsers =(token)=>{
    return api.get(`/user`)
}

export const createUser =(data, token)=>{
    return api.post(`/user`, data)
}

export const updateUser =(id, data, token)=>{
    return api.put(`/user/${id}`, data)
}

export const deleteUser =(id, token)=>{
    return api.delete(`/user/${id}`)
}

export const getActions =(id, token)=>{
    return api.get(`/Actions/${id}`)
}


export const changePassword =(data, token)=>{
    return api.post(`/ChangePassword`, data)
}

//get last update
export const getLastUpdateDate =(token)=>{
    return api.get(`/LastUpdate`)
}

//get menu
export const getUserMenu =(userTypeId)=>{
    return api.get(`/SubMenu/Permitted/${userTypeId}`)
}

//logout
export const logout =(userId)=>{
    return api.post(`/Logout/${userId}`)
}

//check for on boarding
export const onBoarding = async(data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return await api.get(`/OnboardingProcess?IsTally=${data}`, config)
}