import React from 'react'
import Branch from '../pages/masterFiles/Branch'

function TallyBranch({getOnboardingStep, goToNextStep, goToPreviousStep}) {
  return (
    <div>
        <Branch onBoarding={true}/>
    </div>
  )
}

export default TallyBranch