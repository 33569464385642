import React, { useEffect, useState } from 'react'
import { useSignalAbort } from '../api/useSignalAbort';
import { handleApiErrors } from '../api/HandleApiErrors';
import { getCustomerLicenseKey } from '../api/connectorApi';

function InitialPage({customerId}) {
  const abortConstants = useSignalAbort()
  const [licenseKey, setLicenseKey] = useState()
  const [isLoading, setIsLoading] = useState({})

  const getLicenseKey = async () => {
    try {
      const result = await getCustomerLicenseKey(customerId, abortConstants.controllerConfig);
      console.log(result);
      setLicenseKey(result?.data?.UserSubscriptions?.find(item=>item.Status === 'Active')?.LicenceKey)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Connectors', 'connetor_response', 'connetor_timeout', 'connetor_request', setIsLoading)
    }
  };

  useEffect(()=>{
    if(customerId){
      getLicenseKey()
    }
  }, [customerId])

  return (
    <div className='d-flex flex-column pt-2'>
      <span className='tally tally-headline mb-5'>RCOR Connector Download Page</span>
      <span className='mb-3 tally tally-subtitle'>Easily connect your Tally data with RCOR Intelliger :</span>
      <a className='tally tally-subtitle mb-5' href='https://store.rcorintelliger.co/Connector/TallyConnector.zip'>Download RCOR Connector.zip</a>
      <span className='tally tally-subtitle tally-span'>Your License Key : {licenseKey}</span>
      <ul className='mt-3'>
        <li className='tally tally-subtitle mb-1'>Download and Install the RCOR Connector on the same location where Tally is running. </li>
        <li className='tally tally-subtitle'>Open the RCOR Connector and enter the provided license key.</li>
      </ul>
    </div>
  )
}

export default InitialPage