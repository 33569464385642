import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import i18n from './i18n'
import LocaleContext from './LocaleContext';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer and styles
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Logout from './pages/Logout';
import PageNotFound from './pages/PageNotFound';
import MainHome from './pages/MainHome';
import PrivateRoute from './components/PrivateRoute';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/styles/theme';
import DrawerComponent from './components/DrawerComponent';
import Login from './pages/Login';
import SalesPurchase from './TabularReports/SalesPurchase/SalesPurchase';
import PaymentReceipt from './TabularReports/PaymentReceipt/PaymentReceipt';
import JournalLedger from './TabularReports/JournalLedger/JournalLedger';
import { AppProvider } from './AppContext';
import WrongUrl from './pages/WrongUrl';
import OnboardingHome from './CustomerOnboarding/OnboardingHome';
import UserManagement from './pages/masterFiles/UserManagement';
import RoleSettings from './pages/masterFiles/RoleSettings';
import ProfitLoss from './TabularReports/ProfitLoss/ProfitLoss';
import ProfitLossReport from './TabularReports/ProfitLoss/ProfitLossReport';
import TrialBalance from './TabularReports/ProfitLoss/TrialBalance';
import BalanceSheet from './TabularReports/ProfitLoss/BalanceSheet';
import InventoryRegister from './TabularReports/InventoryReg/InventoryRegister';
import Home from './zoho/Home';
import AccountsDashboard from './zoho/AccountsDashboard';
import NoWorkspace from './pages/NoWorkspace';
import NoIntegrations from './pages/NoIntegrations';
import Datasource from './datasource/Datasource';
import DatasourceOnboarding from './datasource/DatasourceOnboarding';
import { NotificationProvider } from './pages/firebase/NotificationContext';
import { handleForegroundMessages } from './pages/firebase/FirebaseForegroundHandler';

const Branch = lazy(() => import('./pages/masterFiles/Branch'));
const FinancialYear = lazy(() => import('./pages/masterFiles/FinancialYear'));
const CompanySettings = lazy(() => import('./pages/Settings/CompanySettings/CompanySettings'));
const MainDashborad = lazy(() => import('./mainpage/MainDashborad'));
const ChartOfAccount = lazy(() => import('./chartOfAccount/ChartOfAccount'));
const EventSettings = lazy(() => import('./pages/Settings/Events/EventSettings'));
const SalesHome = lazy(() => import('./sales/SalesHome'));
const PurchaseHome = lazy(() => import('./inventory/PurchaseHome'));

const DayBook = lazy(() => import('./TabularReports/DayBook/DayBook'));
const CashBankLedger = lazy(() => import('./TabularReports/CashBankLedger/CashBankLedger'));

function App() {

  const [locale, setLocale] = useState(i18n.language)

  useEffect(() => {
    const languageChange = (lng) => setLocale(lng);
    i18n.on('languageChanged', languageChange);

    // Cleanup the subscription when the component unmounts
    return () => {
      i18n.off('languageChanged', languageChange);
    };
  }, [locale]);

  //  i18n.on('languageChanged', (lng)=>setLocale(i18n.language))
  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value)
  }
  
  // const generateToken = async ()=>{
  //   const permission = await Notification.requestPermission();
  // }
  // useEffect(() => {
  //   // handleForegroundMessages((message) => {
  //   //   console.log("Foreground message in App.js:", message);
  //   // });
  //   generateToken();
  // }, []);

  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //     .then(registration => {
  //       console.log('Service worker registered successfully:', registration);
  //     })
  //     .catch(error => {
  //       console.error('Service worker registration failed:', error);
  //     });
  // } else {
  //   console.warn('Service workers are not supported in this browser.');
  // }

  return (
    <ThemeProvider theme={theme}>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        {/* <NotificationProvider> */}
        <div className="app" dir={i18n.dir()}>
          <AppProvider>
            <BrowserRouter>
              <Routes>
                {/* <Route path='/loginpage' element={<Suspense fallback={<div>Loading...</div>}><NewLogin /></Suspense>} /> */}
                <Route path='/loginpage' element={<Login />} />
                <Route path='/' element={<MainHome />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/OnBoarding' element={<OnboardingHome />} />
                <Route path='/no_integration' element={<NoIntegrations />} />
                <Route path='/onBoarding_datasource' element={<DatasourceOnboarding />} />
                <Route element={<Suspense fallback={<div></div>}><DrawerComponent handleLanguageChange={handleLanguageChange} languageDirection={i18n.dir()} /></Suspense>}>
                  <Route path='/home' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><MainDashborad /></Suspense></PrivateRoute>} />
                  {/* <Route path='/new_dashboard' element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} /> */}
                  <Route path='/dashboard' element={<Suspense fallback={<div>Loading...</div>}><AccountsDashboard /></Suspense>} />
                  <Route path='/branch' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><Branch /></Suspense></PrivateRoute>} />
                  <Route path='/financial_year' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><FinancialYear /></Suspense></PrivateRoute>} />
                  <Route path='/user_management' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><UserManagement /></Suspense></PrivateRoute>} />
                  <Route path='/Roles' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><RoleSettings /></Suspense></PrivateRoute>} />
                  <Route path='/company' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><CompanySettings /></Suspense></PrivateRoute>} />
                  <Route path='/DataSource' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><Datasource /></Suspense></PrivateRoute>} />
                  <Route path='/Events' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><EventSettings /></Suspense></PrivateRoute>} />
                  <Route path='/sales' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><SalesHome /></Suspense></PrivateRoute>} />
                  <Route path='/chart_of_accounts' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><ChartOfAccount /></Suspense></PrivateRoute>} />
                  <Route path='/purchase' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><PurchaseHome /></Suspense></PrivateRoute>} />
                  <Route path='/DayBook' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><DayBook /></Suspense></PrivateRoute>} />
                  <Route path='/CashBankLedger' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><CashBankLedger /></Suspense></PrivateRoute>} />
                  <Route path='/InventoryRegister' element={<Suspense fallback={<div>Loading...</div>}><InventoryRegister /></Suspense>} />
                  <Route path='/SalesPurchase' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><SalesPurchase /></Suspense></PrivateRoute>} />
                  <Route path='/PaymentReceipt' element={<PrivateRoute><Suspense fallback={<div>Loading...</div>}><PaymentReceipt /></Suspense></PrivateRoute>} />
                  <Route path='/JournalLedger' element={<Suspense fallback={<div>Loading...</div>}><JournalLedger /></Suspense>} />
                  <Route element={<Suspense fallback={<div>Loading...</div>}><ProfitLoss /></Suspense>} >
                    <Route path='/P&L' element={<Suspense fallback={<div>Loading...</div>}><ProfitLossReport /></Suspense>} />
                    <Route path='/trial_balance' element={<Suspense fallback={<div>Loading...</div>}><TrialBalance /></Suspense>} />
                    <Route path='/balance_sheet' element={<Suspense fallback={<div>Loading...</div>}><BalanceSheet /></Suspense>} />
                  </Route>
                  <Route path='/no_workspace' element={<NoWorkspace />} />
                </Route>
                <Route path='*' element={<PageNotFound />} />
                <Route path='/invalid_url' element={<WrongUrl />} />
              </Routes>
            </BrowserRouter>
          </AppProvider>
          {/* Include the ToastContainer at the root level */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
          />
        </div>
        {/* </NotificationProvider> */}
      </LocaleContext.Provider>
    </ThemeProvider>

  );
}

export default App;
