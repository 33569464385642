import React from 'react'
import Header from '../CustomerOnboarding/Header'
import ConnectorForm from './ConnectorForm'
import { useSelector } from 'react-redux';
import DatasourceTable from './DatasourceTable';

function Datasource() {
    const user = useSelector(state => state.user);
    return (
        <div>
            {/* <Header /> */}
            <div className='mt-5'>
            <DatasourceTable id={user?.CustomerId} />
            </div>
        </div>
  )
}

export default Datasource