import React from 'react'
import UserForm from '../components/forms/UserForm'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function TallyUser({getOnboardingStep, goToPreviousStep}) {
    const user = useSelector(state => state.user);

    const userUpdate = (message) => {
        toast.success(message, {
            autoClose: 3000, // Close the toast after 5 seconds
        });
        getOnboardingStep()
    };
    return (
        <div>
            <UserForm onBoarding={true} isEdit={true} userId={user?.userid} onUserUpdated={userUpdate}/>
        </div>
    )
}

export default TallyUser